<template>
  <div :key="counst">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
          <ayl-table-with-query-object :table="table">
            <div slot="ctrl-button" style="line-height: 32px">
              <el-button
              class="ml40px"
              style="margin-right: 15px"
              type="primary"
              @click="onAllExport"
              >导出</el-button
            >
            </div>
          </ayl-table-with-query-object>
      </div>
    </div>

    <!--导出-->
    <el-dialog
      title="选择导出的数据"
      :visible.sync="dialogAllExport"
      width="900px"
      center
    >
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选</el-checkbox
      >
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox
          :label="item.index"
          v-for="(item, idx) in checkList"
          :key="idx"
          >{{ item.value }}</el-checkbox
        >
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="exportAllExcel"
          class="mr24px"
          :loading="btnLoading"
          >确 定</el-button
        >
        <el-button @click="dialogAllExport = false">取 消</el-button>
      </span>
    </el-dialog>
    <!--导出-->
    <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogExport=false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
    title="新增结算"
    :visible.sync="centerDialogVisible"
    width="900px"
    center>
    <div class="content-box">
      <div class="content-main" style="-webkit-box-shadow: none;box-shadow: none;border-radius: 4px;border: none;">
        <el-form :model="form" :rules="rules" ref="form">
            <el-form-item label="交易类型" :label-width="formLabelWidth">
                <el-input v-model="form.tradeType" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="应付金额" :label-width="formLabelWidth">
                <el-input v-model="form.payableAmount" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="已付金额" :label-width="formLabelWidth">
                <el-input v-model="form.payedAmount" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="应付余额" :label-width="formLabelWidth">
                <el-input v-model="form.payableBalance" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="交易日期" :label-width="formLabelWidth" prop="settlementDate">
                <el-date-picker
                v-model="form.settlementDate"
                type="date"
                autocomplete="off"
                value-format="timestamp"
                placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="交易金额" :label-width="formLabelWidth" prop="tradeAmount">
                <el-input v-model="form.tradeAmount" autocomplete="off" placeholder="请输入" maxlength="32" type="number"></el-input>
            </el-form-item>
            <el-form-item label="结算方式" :label-width="formLabelWidth" prop="settlementForm">
                <el-select v-model="form.settlementForm" placeholder="请选择" value-key="id" @change='changeSelect'>
                <el-option v-for="(item, idx) in settlementNewData"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item label="对方账号" :label-width="formLabelWidth" prop="partnerAcc">
                <el-input v-model="form.partnerAcc" autocomplete="off" placeholder="请输入" maxlength="32"></el-input>
            </el-form-item>
            <el-form-item label="对方户名" :label-width="formLabelWidth" prop="partnerAccName">
                <el-input v-model="form.partnerAccName" autocomplete="off" placeholder="请输入" maxlength="32"></el-input>
            </el-form-item>
            <el-form-item label="对方开户银行" :label-width="formLabelWidth" prop="partnerAccBank">
                <el-input v-model="form.partnerAccBank" autocomplete="off" placeholder="请输入" maxlength="32"></el-input>
            </el-form-item>
            <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input v-model="form.settlementRemark" autocomplete="off" placeholder="请输入(选填)" maxlength="64"></el-input>
            </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm" :loading="submitLoading">确 定</el-button>
    </span>
    <!-- 订单数量导出 -->
    </el-dialog>
    <el-dialog
    title="外请车订单明细"
    :visible.sync="ordersDialogVisible"
    width="1300px"
    center>
      <div style="margin-bottom: 10px">
        <el-button type="primary" plain @click="onExport">全部导出</el-button>
      </div>
      <div class="content-main">
        <ayl-table :table="ordersTable">
        </ayl-table>
      </div> 
    </el-dialog>
  </div>
</template>

<script>
import mixins from '../../../utils/mixins'
  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      const vm = this
      return {
        ordersDialogVisible: false,
        idList: null,

        counst: 0,
        dialogExport: false,
        dialogAllExport: false,
        checkList: [],
        checkIndex: [],
        btnLoading: false,
        centerDialogVisible: false,
        submitLoading: false,
        settlementNewData: [],       //结算方式列表
        form: {
          accountCheckingId: null,          //对账单编号
          tradeType: '应付',                //交易类型
          payableAmount: null,         //应付金额
          payedAmount: null,            //已付金额
          payableBalance: null,        //应付余额
          settlementDate: (new Date()).valueOf(),           //交易日期
          tradeAmount: null,              //交易金额
          settlementForm: null,           //结算方式
          companyAcc: null,               //账户户名--账户账号
          partnerAcc: null,               //对方账户
          partnerAccName: null,           //对方户名
          partnerAccBank: null,           //对方开户银行
          settlementRemark: null,         //备注
        },
        rules: {
            settlementDate: [{required: true, message: '请选择交易日期', trigger: 'change'}],
            tradeAmount: [{max: 36, required: true, message: '请输入交易金额', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
            settlementForm: {required: true, message: '请选择结算方式', trigger: 'change'},
            partnerAcc: [{max: 32, required: true, message: '请输入对方账号', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
            partnerAccName: [{max: 32, required: true, message: '请输入对方户名', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
            partnerAccBank: [{max: 32, required: true, message: '请输入对方开户银行', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
        },
        formLabelWidth: '150px',
        nav: [{name: "出纳结算", path: ""}, {name: "外请车结算", path: ""}],
        table: {
          api: vm.$api.cashierSettlement_getWQAccountChecking,
          query: {
            begin: new Date().setTime(new Date(new Date().setMonth(new Date().getMonth() - 1))),
            end: new Date().getTime(),
            settlementStatus: 0,
            queryContent: null,
          },
          // 是否显示分页
          // hidePagination: true,
          // 表格查询的条件组件
          searchData: [
            {
              type: 'dateSection_zl',
              title: '对账单生成日期',
              isOptions: true,
              // model: [],
              modelName: ['begin', 'end'],
            },
            {
                type: 'select',
                title: '结算情况',
                model: 'settlementStatus',
                placeholder: '请选择',
                width: '150px',
                option: [
                  {value: 0,label: '未结清 '},
                  {value: 1,label: '已结清 '}
                ],
            },
            {
              type: 'input',
              model: 'queryContent',
              placeholder: '对账账单名称'
            }],
          columns: [
            //   {
            //   title: '序号',
            //   width: '50px',
            //   $index: 'index',
            // },
            {
              title: '对账单名称',
              width: '160px',
              key: 'accountCheckingName',
               showTooltip: true,
              fixed: "left",
            }, {
              title: '对账单编号',
              width: '190px',
              key: 'accountCheckingId',
              fixed: "left",
              showTooltip: true,
            }, {
              title: '结算客户',
              key: 'partnerAccountName',
              width: '140px',
              fixed: "left",
              showTooltip: true,
            }, {
              title: '对账单生成日期',
              width: '160px',
              key: 'generateDate',
              fixed: "left",
            },{
              title: '对账单确认日期',
              width: '160px',
              key: 'confirmDate',
              fixed: "left",
            }, {
              title: '对账订单数',
              width: '120px',
              key: 'orderCount',
              fixed: "left",
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': true,
                    },
                    on: {
                      click: vm.orders.bind(this, ctx.row)
                    }
                  }, ctx.row.orderCount)
                ])
              },
            }, {
              title: '应付金额',
              width: '120px',
              key: 'payableAmount',
            }, 
            // {
            //   title: '业务类型',
            //   render (h, ctx) {
            //     return h('span', [
            //       h('span', {
            //         style: {
            //           color: ctx.row.orderType === '直拨' ? vm.$config.color_z : ctx.row.orderType === '内转' ? vm.$config.color_n : vm.$config.color_w
            //         }
            //       }, ctx.row.orderType||'- -')
            //     ])
            //   }
            // }, 
            {
              title: '已付金额',
              width: '120px',
              key: 'payedAmount',
              showTooltip: true,
            }, {
              title: '应付余额',
              width: '120px',
              key: 'payableBalance',
              showTooltip: true,
            }, {
              title: '对账金额',
              width: '120px',
              key: 'accountCheckingAmount',
              showTooltip: true,
            }, {
              title: '对账状态',
              width: '120px',
              key: 'accountStatus',
              render: (h,ctx)=> {
                let text = ctx.row.accountStatus == 1?"对账已完成":'对账中'
                if(ctx.row.accountStatus===null){
                   return h('span','- -')
                }else {
                   return h('span',text)
                }
              },
            }, {
              title: '结清情况',
              width: '120px',
              key: 'settlementStatus',
              render: (h,ctx)=> {
                let text = ctx.row.settlementStatus == 1?"已结清":'未结清'
                if(ctx.row.settlementStatus===null){
                   return h('span','- -')
                }else {
                   return h('span',text)
                }
              },
            },
            // {
            //   title: '提成系数(%)',
            //   width: '120px',
            //   key: 'driverBonusFee',
            // }, 
            {
              title: '操作',
              align: "center",
              width: '155px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': true,
                    },
                    on: {
                      click: vm.goEdit.bind(this, ctx.row)
                    }
                  }, '结算明细'),
                  h('span', {
                    class: {
                      'table-view': ctx.row.settlementStatus ==='已结清' ? false : true,
                      'table-class': ctx.row.settlementStatus ==='已结清' ? true : false,
                    },
                    on: {
                      click: ctx.row.settlementStatus ==='已结清' ? vm.goNull : vm.addUnit.bind(this, ctx.row)
                    }
                  }, '新增结算')
                ])
              }
            }]
        },
        //订单明细
        ordersTable: {
          api: vm.$api.listOrderByOrderList,
          query: {
            orderIdList: []
          },
          columns: [
            // {
            //   type: 'selection',
            //   width: '50px',
            // },
            {
              title: '客户名称',
              showTooltip: true,
              key: 'partnerName',
            }, {
              title: '结算客户',
              showTooltip: true,
              key: 'partnerAccountName',
            }, {
              title: '运输日期',
              width: '100px',
              filter: 'str2ymd',
              key: 'transportTime',
            }, {
              title: '提货车号',
              width: '100px',
              key: 'plateNumber',
            }, {
              title: '驾驶员',
              width: '100px',
              key: 'driverName',
            }, {
              title: '业务类型',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    style: {
                      color: ctx.row.orderType === '直拨' ? vm.$config.color_z : ctx.row.orderType === '内转' ? vm.$config.color_n : vm.$config.color_w
                    }
                  }, ctx.row.orderType||'- -')
                ])
              }
            }, {
              title: '起运地',
              showTooltip: true,
              key: 'sendAddressDetail',
            }, {
              title: '目的地',
              showTooltip: true,
              key: 'receiveAddressDetail',
            }, {
              title: '订单编号',
              width: '160px',
              key: 'orderId',
            }, {
              title: '应付金额',
              width: '100px',
              key: 'cost',
            }]
        }
      }
    },
    watch: {
     ordersTable() {
        this.ordersDialogVisible = true;
      }
    },
    methods: {
      onAllExport(onExport) {
        this.dialogAllExport = true;
        this.$api.GetExcelExportKeyAndColumn({
          excelKey: "getWQAccountChecking",
        })
        .then((res) => {
          this.checkList = res.chsList;
          this.checkIndex = this.checkList.map((e) => {
            return e.index;
          });
        });

      },
      // 导出
      async exportAllExcel() {
        this.btnLoading = true;
        try {
          const url = await this.$api.Excel({
            excelKey: "getWQAccountChecking",
            indexList: this.checkIndex,
            params: {
              settlementStatus: this.table.query.settlementStatus,
              queryContent: this.table.query.queryContent,
              startTime: this.table.query.begin,
              endTime: this.table.query.end,
            },
          });
          window.open(url);
          this.dialogAllExport = false;
        } catch (e) {
          // console.log(e)
        }
        this.btnLoading = false;
      },
      // 导出
      async onExport(){
        const url = await this.$api.Excel({
          excelKey: "orderListExport",
          indexList: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17],
          params: {
            orderIds: this.idList
          },
        });
        window.open(url);
      },
      //订单详情
      async orders(val) {
          this.ordersDialogVisible = true
          this.idList = val.orderIdList
          this.ordersTable.query.orderIdList = val.orderIdList
          await this.$nextTick(() => {
            this.$search(this.ordersTable)
          });
      },

      //结算明细
      goEdit(val){
        this.$router.push({
          path: '/cashier-settlement/owner-outside/owner-outside-details',
          query: {
            accountCheckingId: val.accountCheckingId,
            driverName: val.driverName,
            settlementStatus: val.settlementStatus
          }
        })
      },
      goNull(){},
      async addUnit(val){
          this.form.accountCheckingId = val.accountCheckingId
          let byAccountData = await this.$api.getAccountCheckingInfoByAccountCheckingId({
              accountCheckingId: val.accountCheckingId,
          })
          this.form.settlementDate = (new Date()).valueOf(),           //交易日期
          this.form.tradeAmount = null,              //交易金额
          this.form.settlementForm = null,           //结算方式
          this.form.companyAcc = null,               //账户户名--账户账号
          //this.form.partnerAcc = null,               //对方账户
          //this.form.partnerAccName = null,           //对方户名
          //this.form.partnerAccBank = null,           //对方开户银行
          this.form.settlementRemark = null,         //备注
          this.centerDialogVisible = true
          this.form.payableAmount = byAccountData.payableAmount,
          this.form.payedAmount = byAccountData.payedAmount,
          this.form.payableBalance = byAccountData.payableBalance,
          this.form.partnerAcc = val.accNumber,
          this.form.partnerAccName = val.driverName,
          this.form.partnerAccBank = val.accBank

          let driverIdList = await this.$api.driverGet({
            driverId: byAccountData.accountCheckingObjectId
          })
          this.form.partnerAcc = driverIdList.accNumber
          this.form.partnerAccName = driverIdList.driverName
          this.form.partnerAccBank = driverIdList.accBank
          console.log(driverIdList)
          this.settlementNewData = []
          let settlementData = await this.$api.settlementList({})
          for(let i =0; i<settlementData.length;i++){
              this.settlementNewData.push({
                  lable: settlementData[i].accName,
                  value: settlementData[i].accName,
                  accId: settlementData[i].accId,
              })
          }
      },
      changeSelect(val) {
        let obj = {};
        obj = this.settlementNewData.find((item)=>{
            return item.lable === val;
        });
        this.form.companyAcc = obj.accId;
      },
      //新增明细
      async addForm(){
          // if(parseFloat(this.form.payableBalance) < parseFloat(this.form.tradeAmount)){
          //   this.$message.error('交易金额应小于等于应付余额');
          //   return
          // }
          await this.$api.insertDetailInfo({
              steelSettlementDetailInfoVO: {
                  accountCheckingId: this.form.accountCheckingId,
                  settlementType: '外请车结算',
                  tradeType: this.form.tradeType,
                  companyAcc: this.form.companyAcc,
                  settlementDate: this.form.settlementDate,
                  tradeAmount: this.form.tradeAmount,
                  settlementForm: this.form.settlementForm,
                  partnerAcc: this.form.partnerAcc,
                  partnerAccName: this.form.partnerAccName,
                  partnerAccBank: this.form.partnerAccBank,
                  settlementRemark: this.form.settlementRemark,
              }
          })
          this.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success'
          });
          this.centerDialogVisible = false
          await this.$search(this.table)
      },
      submitForm() {
        this.$refs.form.validate(async valid => {
          if (!valid) return
          this.submitLoading = true
          try {
            this.addForm()
          // eslint-disable-next-line no-empty
          } catch (e){}
          this.submitLoading = false
        })
      },

      // 导出
      async exportExcel(){
        this.btnLoading = true
        try {
          const url = await this.$api.listOrderExcel({
            params: {
              begin: this.table.query.begin,
              end: this.table.query.end,
              settlementStatus: this.table.query.settlementStatus,
              queryContent: this.table.query.queryContent,
            },
            excelKey: 'DriverSettlement',
            indexList: this.checkIndex,
          })
          window.open(url)
          this.dialogExport = false
        }catch (e) {}
        this.btnLoading = false
      },
    },
    beforeRouteEnter (to, from, next) {
      if (from.path === '/cashier-settlement/owner-outside/owner-outside-details') {
        to.meta.isBack = true
      } else {
        to.meta.isBack = false
      }
      next()
    },
    async activated () {
      if (!this.$route.meta.isBack) {
        this.counst++
        this.table.query.settlementStatus = 0;
        this.table.query.queryContent = null;
        this.table.query.begin = new Date().setTime(new Date(new Date().setMonth(new Date().getMonth() - 1)));
        this.table.query.end = new Date().getTime();
        await this.$search(this.table)
      } else {
          await this.$search(this.table)
          this.$route.meta.isBack = false // 重置详情页标识isBack
      }
      
      // 导出
      this.$api.GetExcelExportKeyAndColumn({
        excelKey: "DriverSettlement"
      }).then((res)=>{
        this.checkList = res.chsList
        this.checkIndex = this.checkList.map(e=>{
          return e.index
        })
      })
    },
    // async activated () {
    //   await this.$search(this.table)
    // },
  }
</script>

<style lang='sass' scoped>
.content-box /deep/ .el-form-item
    width: 41%
    display: inline-block

/deep/ .el-dialog__header
    border-bottom: 1px solid $grayShallow3

/deep/.el-date-editor.el-input
    width: 160px
</style>
